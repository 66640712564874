var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-4"},[(_vm.selectedContentForTriggerEdit.triggers.tweet !== undefined)?_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"width":"480px","height":"280px","overflow":"hidden","background-image":"url(twitter_safespace.png)"}},[(
            _vm.selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
              'mp4' ||
            _vm.selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
              'webm'
          )?_c('video',{style:({
            top: _vm.cssPlacementCoords.y + 'px',
            left: _vm.cssPlacementCoords.x+ 'px',
            position: 'relative',
            width: _vm.contentNewWidth + 'px',
            height: _vm.contentNewHeight + 'px',
          }),attrs:{"controls":"","src":_vm.selectedContentForTriggerEdit.media.url}}):_c('img',{style:({
            top: _vm.cssPlacementCoords.y  + 'px',
            left: _vm.cssPlacementCoords.x + 'px',
            position: 'relative',
            width: _vm.contentNewWidth + 'px',
            height: _vm.contentNewHeight + 'px',
          }),attrs:{"src":_vm.selectedContentForTriggerEdit.media.url}})])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","disabled":this.yDisabled},on:{"click":function($event){_vm.moveCoordsY(10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-up ")])],1),_c('v-btn',{attrs:{"disabled":this.yDisabled,"icon":""},on:{"click":function($event){_vm.moveCoordsY(-10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-down ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":this.xDisabled},on:{"click":function($event){_vm.moveCoordsX(10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":this.xDisabled},on:{"click":function($event){_vm.moveCoordsX(-10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1):(_vm.selectedContentForTriggerEdit.triggers.instagram !== undefined)?_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"width":"360px","height":"360px","overflow":"hidden","background-image":"url(insta_safespace.png)"}},[(
            _vm.selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
              'mp4' ||
            _vm.selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
              'webm'
          )?_c('video',{style:({
            top: _vm.cssPlacementCoords.y + 'px',
            left: _vm.cssPlacementCoords.x + 'px',
            position: 'relative',
            'max-width': '100%',
          }),attrs:{"controls":"","src":_vm.selectedContentForTriggerEdit.media.url}}):_c('img',{style:({
            top: _vm.cssPlacementCoords.y  + 'px',
            left: _vm.cssPlacementCoords.x  + 'px',
            position: 'relative',
            'max-width': '100%',
          }),attrs:{"src":_vm.selectedContentForTriggerEdit.media.url}})])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","disabled":this.yDisabled},on:{"click":function($event){_vm.moveCoordsY(10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-up ")])],1),_c('v-btn',{attrs:{"disabled":this.yDisabled,"icon":""},on:{"click":function($event){_vm.moveCoordsY(-10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-down ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":this.xDisabled},on:{"click":function($event){_vm.moveCoordsX(10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":this.xDisabled},on:{"click":function($event){_vm.moveCoordsX(-10); _vm.updateCoordsParent();}}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Content not from social media.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }