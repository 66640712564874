<template>
  <v-card
    elevation="2"
    class="ma-0 pa-0"
  >
    <v-card-title class="primary white--text ma-0 d-flex justify-center">
      {{ resourceAvailable.resourceType.name }}
    </v-card-title>

    <v-col
      cols="12"
      class='text-center'
    >
      <span class="ma-4 text-center">{{ resourceAvailable.resourceType.description }}</span>
    </v-col>
<!-- ***************Upload Area*************** -->

<div v-if="resourceAvailable.resourceType.type === 'media'">
  <v-col cols="12">
    <div @dragover.prevent @drop.prevent>
      <input
        :ref="'media_resource'+resourceAvailable.resourceId"
        type="file"
        hidden
        @change="saveImage($event, resourceAvailable, 'resource'+resourceAvailable.resourceId)"
       
      />
    </div>
    <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
    <v-hover v-slot="{ hover }">
      <div
        :ref="'media_resource'+resourceAvailable.resourceId + '_drop'"
        @click="$refs['media_resource'+resourceAvailable.resourceId].click()"
        v-bind="attrs"
            v-on="on"
        style="
          background-color: transparent;
          border-radius: 20px;
          border: grey 1px dashed;
          padding: 10px;
        "
        @dragover.prevent
        @drop.prevent="
          saveImage(
            $event,
            resourceAvailable,
            'media_resource'+resourceAvailable.resourceId + '_drop'
          );
          $refs['media_resource'+resourceAvailable.resourceId + '_drop'].style.backgroundColor = 'transparent';
        "
        @dragleave="$refs['media_resource'+resourceAvailable.resourceId + '_drop'].style.backgroundColor = 'transparent'"
        @dragover="$refs['media_resource'+resourceAvailable.resourceId + '_drop'].style.backgroundColor = 'grey'"
        :style="hover ? { border: 'solid 1px rgba(255,255,255, .8)' } : { border: 'solid 1px rgba(255,255,255, .3)' }"
        class="rounded"
      >

        
          <div style="display: inline-block; text-align: right; width: 55%">
            
                <v-btn icon >
                  <v-icon large>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
            
          </div>
        
      </div>
    </v-hover>
  </template>
              <span>Upload Creative</span>
            </v-tooltip>
  </v-col>
</div>
    
<!-- ************CSS STYLE START**************** -->
<div v-if="resourceAvailable.resourceType.type === 'textarea'">
  <v-col
    cols="12"
    class="py-0 my-0 mt-4"
  >
    <br/>
    
    <v-btn
      v-if="resourceAvailable.resourceType.name.toLowerCase() === 'css style'"
      @click="prettifyCSS = !prettifyCSS"
      fab
      small
      color="primary"
      class="mb-3"
    >
      <v-icon small>{{ (prettifyCSS) ? 'mdi-language-css3' : 'mdi-file-xml-box' }}
      </v-icon>
    </v-btn>

    <v-textarea
      v-if="resourceAvailable.resourceType.name.toLowerCase() === 'css style'"
      :ref="'textarea_resource' + resourceAvailable.resourceId"
      
      :value="(prettifyCSS) ? cssFormatter : resourceAvailable.text"
      auto-grow
      style="overflow-y: auto; height: calc(60vh - 100px); background-color: black; padding: 0px; padding: 10px;"
    />

    <v-textarea
      v-else
      :ref="'textarea_resource' + resourceAvailable.resourceId"
      
      :value="resourceAvailable.text"
      auto-grow
      style="overflow-y: auto; height: calc(60vh - 100px); background-color: black; padding: 0px; padding: 10px;"
    />
    <div
      v-if="['custom javascript','css custom.css','css style'].includes(resourceAvailable.resourceType.name.toLowerCase())"
      :id="resourceAvailable.resourceType.name.toLowerCase().replace(' ','').replace('.','')"
      auto-grow
      style="overflow-y: auto; height: calc(60vh - 100px); background-color: black; padding: 0px; padding: 10px;"
      :load="setupCodeMirror(resourceAvailable.resourceType.name.toLowerCase(),this)"
    >
    </div>
  </v-col>
  <v-col
    cols="12"
    class="py-4 text-center"
  >
    <v-btn
      color="primary"
      @click="setText(resourceAvailable, `textarea_resource${resourceAvailable.resourceId}`)"
    >
      Save
    </v-btn>
  </v-col>
</div>

<!-- *************CSS STYLE END*************** -->
    <div v-if="resourceAvailable.resourceType.type === 'text'">
      <v-col
        cols="12"
        class="py-0 my-0 mt-4"
      >
        <v-text-field
          :ref="'text_resource'+resourceAvailable.resourceId"
          outlined
          :value="resourceAvailable.text"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-4 text-center"
      >
        <v-btn
          color="primary"
          @click="setText(resourceAvailable, `text_resource${resourceAvailable.resourceId}`)"
        >
          Save
        </v-btn>
      </v-col>
    </div>
    <v-col
      cols="12"
        class='text-center'
        v-if="resourceAvailable.media"
    >
      <div class="d-flex justify-center mx-5">
        <v-img
          class='text-center'
          :src="resourceAvailable.media.urlThumb"
          contain
          max-width="96%"
          max-height="300"
        />
      </div>
      <v-row style="padding: 15px;
">
          <v-col 
            cols="12"
            class="d-flex align-center"
          >
            {{resourceAvailable.media.originalFileName}}
          </v-col>
          <v-col 
            cols="0"
            class="d-flex justify-center align-center"
          >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      @click.prevent="downloadItem({media: resourceAvailable.media})"
                    >
                      <v-icon color="primary">
                        mdi-download-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Download</span>
              </v-tooltip>
          </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
import CampaignController from '@/services/controllers/Campaign'
import axios from 'axios'

export default {
  props: {
      resource: {
          type: Object,
          default: () => ({}),
      },
      campaignBurstScreenId: {
          type: Number,
          default: () => 0
      },
      campaignBurstId: {
          type: Number,
          default: () => 0
      },
      campaignId: {
          type: Number,
          default: () => 0
      }
  },

  data() {
    return { 
      resourceAvailable: { ...this.resource },
      cid: this.campaignId, 
      bid: this.campaignBurstId, 
      cbsid: this.campaignBurstScreenId,
      prettifyCSS: true,
    };
  },
  computed: {
    cssFormatter() {
      let result = ''
      let { text } = this.resourceAvailable
      
      if(!text) // text might be null when the 'css styles' resource is newly added to a screen
        return result 

      text = text.replace(/text_scaling='false'|text_scaling='true'|text_scaling="false"|text_scaling="true"| text_scaling='false'| text_scaling='true'| text_scaling="false"| text_scaling="true"/g, '')

      // First split by css id used
      let ids = text.split(/<style id='|<style id="/)

      for (let index = 1; index < ids.length; index++) {
        let id = ids[index];

        id = id.replace(/^/, '#')
        id = id.replace(/'>|">| '>| ">/, ' {')
        id = id.replace('</style>', '}')

        id = id.replaceAll('<![CDATA[', ' {\n\t\t')
        id = id.replaceAll(']]>', '\n\t}')

        id = id.replace(/<\/.*>/g, '')
        id = id.replaceAll('<', '')
        id = id.replaceAll('>', '')
        id = id.replace(/\;(?=.*\;)/g, ';\n\t\t')

        result += id
      }

      return `${result}`
    }
  },

  watch : {
    resource: {
      handler: function(r) {
          this.resourceAvailable = r;
      }
    },
  },

  methods: {
      async downloadItem({ media }) {
        let downloadUrl = media.url
        let downloadFileName = media.originalFileName
        let mimeType = media.mimeType

        let cachebuster = Math.round(new Date().getTime() / 1000)

        const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" })
        const blob = new Blob([response.data], { type: mimeType })
        const link = document.createElement("a")

        link.href = URL.createObjectURL(blob)
        link.download = downloadFileName
        link.click()
        
        URL.revokeObjectURL(link.href)
      },
      async setupCodeMirror(resourceName, element) {
        // Wait for the css and codes to load
        async function waitForLoad() {
          await new Promise(resolve => setTimeout(resolve, 1));
          console.log('Waited for elements to load for CodeMirror');
        }
        await waitForLoad();

        var editorContainer = document.getElementById(resourceName.replace(" ","").replace(".",""));
        editorContainer.style.overflow = "hidden";
//        var cj = document.getElementById("customJavascript");
        var cj = editorContainer.parentElement.querySelector("textarea")
        var code = ""+resourceName +" code here!";
        if(cj) {
          code = cj.value;
        }
        if(window.CodeMirror) console.log("CodeMirror is loaded");
        else console.log("CodeMirror isn't loaded");

//        if(document.getElementById(editorContainer) == null) return "";// && document.getElementById(editorContainer).children.length > 0) return resourceName.replace(" ","");
//        if(editorContainer.parentElement.children[1].style.display == "none") return resourceName.replace(" ","");
        if(window[resourceName.replace(" ","").replace(".","")+"codemirror"] && 
          document.getElementById(resourceName.replace(" ","").replace(".","")) && 
          document.getElementById(resourceName.replace(" ","").replace(".","")).children.length != 0) {
          document.getElementById(resourceName.replace(" ","").replace(".","")).children[0].remove();
            /*
          await waitForLoad();
          console.log("update the value for ",resourceName);
          console.log("code: "+code);

          window[resourceName.replace(" ","").replace(".","")+"codemirror"].setValue(code);
          if(resourceName === 'css style') {
            if(this.prettifyCSS) 
              window.cssstylecodemirror.setOption('mode', "css");
            else
              window.cssstylecodemirror.setOption('mode', "xml");
          }
          return resourceName.replace(" ","")
          */
        }

        window[resourceName.replace(" ","").replace(".","")+"codemirror"] = window.CodeMirror(editorContainer, {
          lineNumbers: resourceName.toLowerCase() == "custom javascript", // true,
          mode: resourceName.toLowerCase().indexOf("css") == -1 ? 'javascript': 'css',
          value: code,
          theme: 'ayu-dark',
          foldGutter: true,
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
          matchBrackets: true,
          styleActiveLine: true,
          lint: true,
          lintOptions: {
            jslint: true,
            showWarnings: true
          }
        });
        window[resourceName.replace(" ","").replace(".","")+"codemirror"].on('lint', (cm, issues) => {
          this.errorLines = issues.map(issue => issue.from.line);
        });
        editorContainer.children[0].style.height = "calc(-100px + 58vh)";
        editorContainer.parentElement.querySelector('.v-textarea').style.display = "none";
//        editorContainer.parentElement.children[1].style.display = "none";
        return resourceName.replace(" ","");
      },
      async setText (resource, resourceRef) {
        if (resource.resourceType.type === 'textarea' || resource.resourceType.type === 'text') {
          let resourceObj = {}
          
          if(resource.resourceType.name.toLowerCase() === 'css style') {
            this.$refs[resourceRef].internalValue = cssstylecodemirror.getValue();
            this.$refs[resourceRef].internalValue = this.formatCssToXml(this.$refs[resourceRef].internalValue)
          } else if(resource.resourceType.name.toLowerCase() === 'css custom.css') {
            this.$refs[resourceRef].internalValue = csscustomcsscodemirror.getValue();
          } else if(resource.resourceType.name.toLowerCase() === 'custom javascript') {
            this.$refs[resourceRef].internalValue = customjavascriptcodemirror.getValue();
          }

          if (resource.resourceType.level === 'screen') {
            resourceObj = {
              campaignBurstScreenId: this.cbsid,
              resourceShortName: resource.resourceType.shortName,
              text: this.$refs[resourceRef].internalValue,
            }
          }

          if (resource.resourceType.level === 'burst') {
            resourceObj = {
              campaignBurstId: this.bid,
              resourceShortName: resource.resourceType.shortName,
              text: this.$refs[resourceRef].internalValue,
            }
          }

          if (resource.resourceType.level === 'campaign') {
            resourceObj = {
              campaignId: this.cid,
              resourceShortName: resource.resourceType.shortName,
              text: this.$refs[resourceRef].internalValue,
            }
          }

          const { data = null } = await CampaignController.setResource(resourceObj)
          // Catch error
          .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

          if(data) {
            this.$root.$emit('snackbarSuccess', 'Saved successfully.')
            this.$emit('refresh', true)
          }
        }
      },

      saveImage (event, resource, ref) {
        const formData = new FormData()

        if (event.dataTransfer === undefined) {
          formData.append('file', event.target.files[0])
        } else {
          formData.append('file', event.dataTransfer.files[0])
        }
        formData.append('campaignId', parseInt(this.cid))
        CampaignController.uploadMedia(formData)
          .then((res) => {
            let resourceObj = {}
            if (resource.resourceType.level === 'screen') {
              resourceObj = {
                campaignBurstScreenId: this.cbsid,
                resourceShortName: resource.resourceType.shortName,
                mediaId: res.data.id,

              }
            }
            if (resource.resourceType.level === 'burst') {
              resourceObj = {
                campaignBurstId: this.bid,
                resourceShortName: resource.resourceType.shortName,
                mediaId: res.data.id,

              }
            }
            if (resource.resourceType.level === 'campaign') {
              resourceObj = {
                campaignId: parseInt(this.cid),
                resourceShortName: resource.resourceType.shortName,
                mediaId: res.data.id,

              }
            }
            CampaignController.setResource(resourceObj)
              .then((res2) => {
                this.$root.$emit('snackbarSuccess', 'Saved successfully.')
                this.$emit('refresh', true) 
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
              })
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      formatCssToXml(css) {
        if(this.prettifyCSS) {
          let newCss = ''

          // Replace # in css props with temp var before splitting by # on next line
          newCss = css.replace(/: #|:#/g, "hexCodeTempVar")
          
          // Convert the id to an xml style tag
          newCss = newCss.replaceAll('#', "<style id='")

          // Replace temp css hex code with a #
          newCss = newCss.replaceAll('hexCodeTempVar', ": #")
          
          // Replace closing brackets with closing chevrons
          newCss = newCss.replace(/{| {|  {|   {|    {|     {/g, '>')
          newCss = newCss.replace(/\s>/g, ">")
          
          // Add opening tags for the img[] & text[] css selectors 
          newCss = newCss.replace(/(text([0-9]*)>)/g, '<$1')
          newCss = newCss.replace(/(img([0-9]*)>)/g, '<$1')
          
          // Split by ids to loop through and structure the css into xml
          let ids = newCss.split("<style id='")

          // Reset new css var to append the result to
          newCss = ''
          
          // Loop through ids (ignore index 0 because its invalid)
          for (let i = 1; i < ids.length; i++) {
            let result = ''
            let id = ids[i];

            // Add the style back because it gets stripped in the above split
            id = id.replace(/^/, "<style id='")
            id = id.replace('>', "'>")

            // Close off style tag
            id = id.substring(0, id.lastIndexOf("}")) + '</style>';

            // Split into id opening tag, props & id closing tag
            let blocks = id.split('<')
            // loop through blocks (ignore index 0 because its invalid)
            for (let j = 1; j < blocks.length; j++) {
              let block = blocks[j];

              // Fix id opening tag
              if (j === 1 || j === blocks.length - 1) {
                block = '<' + block
              }

              else {
                let body = block.split('>')
                
                body[1] = body[1].replace('}', `]]></${body[0]}>`)
                
                body[0] = `<${body[0]}><![CDATA[`

                let header = body[0].trim()
                let footer = body[0].replace('<', '</')
                footer = footer.replace('<![CDATA[', '')
                  
                block = body[0] + body[1]

                let props = block.split('[')

                props = props[2].split(']')

                props = props[0].split(/;/g)

                let newProp = ''

                for (let k = 0; k < props.length - 1; k++) {
                  const prop = props[k].trim()
                  newProp += prop + ';'
                }

                header = (j === 2) ? header : '\t' + header

                block = header + newProp + ']]>' + footer + '\n'
              }              

              result += block
            }

            newCss += (i === 1) ? result : '\n' + result
          }

          this.prettifyCSS = false
          
          return newCss
        }

        return css
      }
  }
};
</script>