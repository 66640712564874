<template>
    <v-breadcrumbs :items="breadcrumbItems">
        <template v-slot:divider>
            <v-icon color="primary">
                mdi-chevron-right
            </v-icon>
        </template>
    </v-breadcrumbs>
</template>
<script>
export default {
    name: 'Crumbs',
    props: ['breadcrumbItems'],
}
</script>