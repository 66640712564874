<template>
    <v-tabs v-model="tab">
        <v-tab v-show="$store.state.Permissions.uploadMediaView">
            Upload Media
        </v-tab>
        <v-tab v-show="$store.state.Permissions.locationView">
            Locations
        </v-tab>
        <v-tab v-show="$store.state.Permissions.airportView && burstDeliveryType === 'dynamic'">
            Airport
        </v-tab>
        <v-tab v-show="$store.state.Permissions.previewView">
            Preview
        </v-tab>
        <v-tab v-show="$store.state.Permissions.resourcesView && burstDeliveryType === 'dynamic'">
            Resources
        </v-tab>
        <v-tab v-show="$store.state.Permissions.settingsView && burstDeliveryType === 'dynamic'">
            Settings
        </v-tab>          
        <v-tab v-show="$store.state.Permissions.screenSpecsView">
            Screen Specs
        </v-tab>
    </v-tabs>
</template>
<script>
export default {
    name: 'Tabs',
    props: ['tab', 'burstDeliveryType'],
    data() {
        return {
            data: null,
        }
    }
}
</script>