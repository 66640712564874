<template>
  <v-row class="pa-4">
    <!-- {{ selectedContentForTriggerEdit.triggers }} -->
    <!-- css placement {{cssPlacementCoords}} -->
    <div v-if="selectedContentForTriggerEdit.triggers.tweet !== undefined">
      <v-col cols="12">
        <div
          style="
            width: 480px;
            height: 280px;
            overflow: hidden;
            background-image: url(twitter_safespace.png);
          "
        >
          <!-- <v-img height="280" width="480" :src='selectedContentForTriggerEdit.media.url'></v-img> -->

          <video
            v-if="
              selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
                'mp4' ||
              selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
                'webm'
            "
            :style="{
              top: cssPlacementCoords.y + 'px',
              left: cssPlacementCoords.x+ 'px',
              position: 'relative',
              width: contentNewWidth + 'px',
              height: contentNewHeight + 'px',
            }"
            controls
            :src="selectedContentForTriggerEdit.media.url"
          ></video>
          <img
            v-else
            :style="{
              top: cssPlacementCoords.y  + 'px',
              left: cssPlacementCoords.x + 'px',
              position: 'relative',
              width: contentNewWidth + 'px',
              height: contentNewHeight + 'px',
            }"
            :src="selectedContentForTriggerEdit.media.url"
          />
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <!-- <v-text-field
            type="number"
            outlined
            label="X"

            v-model="imagePlacementCoords.x"
            @input="updateCoordsParent"
          ></v-text-field> -->

          <v-btn
            icon
            :disabled=this.yDisabled
            @click="moveCoordsY(10); updateCoordsParent();"
            ><v-icon> mdi-arrow-up </v-icon></v-btn
          >
          <v-btn
            :disabled=this.yDisabled
            icon
            @click="moveCoordsY(-10); updateCoordsParent();"
            ><v-icon> mdi-arrow-down </v-icon></v-btn
          >
          <v-btn
            icon
            @click="moveCoordsX(10); updateCoordsParent();"
            :disabled=this.xDisabled
            ><v-icon> mdi-arrow-left </v-icon></v-btn
          >
          <v-btn
            icon
            @click="moveCoordsX(-10); updateCoordsParent();"
            :disabled=this.xDisabled
            ><v-icon> mdi-arrow-right </v-icon></v-btn
          >
          <!-- <v-text-field type='number' outlined label='X' :disabled='selectedContentForTriggerEdit.media.width<selectedContentForTriggerEdit.media.height' v-model="imagePlacementCoords.x " @input='updateCoordsParent'></v-text-field> -->
        </v-col>

        <!-- <v-col cols="6">
          <v-text-field
            type="number"
            outlined
            label="Y"
      
            v-model="imagePlacementCoords.y"
            @input="updateCoordsParent"
          ></v-text-field>
        </v-col> -->
      </v-row>
    </div>

    <!-- {{selectedContentForTriggerEdit.media.fileName.split('.').pop()}} -->
    <div
      v-else-if="selectedContentForTriggerEdit.triggers.instagram !== undefined"
    >
      <v-col cols="12">
        <div
          style="
            width: 360px;
            height: 360px;
            overflow: hidden;
            background-image: url(insta_safespace.png);
          "
        >
          <!-- <v-img height="280" width="480" :src='selectedContentForTriggerEdit.media.url'></v-img> -->

          <video
            :style="{
              top: cssPlacementCoords.y + 'px',
              left: cssPlacementCoords.x + 'px',
              position: 'relative',
              'max-width': '100%',
            }"
            v-if="
              selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
                'mp4' ||
              selectedContentForTriggerEdit.media.fileName.split('.').pop() ===
                'webm'
            "
            controls
            :src="selectedContentForTriggerEdit.media.url"
          ></video>

          <img
            v-else
            :style="{
              top: cssPlacementCoords.y  + 'px',
              left: cssPlacementCoords.x  + 'px',
              position: 'relative',
              'max-width': '100%',
            }"
            :src="selectedContentForTriggerEdit.media.url"
          />
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <!-- <v-text-field
            type="number"
            outlined
            label="X"

            v-model="imagePlacementCoords.x"
            @input="updateCoordsParent"
          ></v-text-field> -->

          <v-btn
            icon
            :disabled=this.yDisabled
            @click="moveCoordsY(10); updateCoordsParent();"
            ><v-icon> mdi-arrow-up </v-icon></v-btn
          >
          <v-btn
            :disabled=this.yDisabled
            icon
            @click="moveCoordsY(-10); updateCoordsParent();"
            ><v-icon> mdi-arrow-down </v-icon></v-btn
          >
          <v-btn
            icon
            @click="moveCoordsX(10); updateCoordsParent();"
            :disabled=this.xDisabled
            ><v-icon> mdi-arrow-left </v-icon></v-btn
          >
          <v-btn
            icon
            @click="moveCoordsX(-10); updateCoordsParent();"
            :disabled=this.xDisabled
            ><v-icon> mdi-arrow-right </v-icon></v-btn
          >
          <!-- <v-text-field type='number' outlined label='X' :disabled='selectedContentForTriggerEdit.media.width<selectedContentForTriggerEdit.media.height' v-model="imagePlacementCoords.x " @input='updateCoordsParent'></v-text-field> -->
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" v-else>
      <span>Content not from social media.</span>
    </v-col>
  </v-row>
</template>
<script>
import { VBtn, VIcon } from "vuetify/lib";
export default {
  name: "FocalPoint",
  props: ["selectedContent", "defaultVal"],
  components: {
    VBtn,
    VIcon,
  },
  created() {
    //when first time loading X:Y from existing triggers divide by this.ratio (this.imagePlacementCoords.x / ratio, this.imagePlacementCoords.y/ratio)
    this.calculateRatios(this.selectedContent);
    if(this.selectedContentForTriggerEdit.triggers.uefaTriggers.focalPoint) {
      this.cssPlacementCoords.x = (this.selectedContentForTriggerEdit.triggers.uefaTriggers.focalPoint.split(":")[0] / this.ratio);
      this.cssPlacementCoords.y = (this.selectedContentForTriggerEdit.triggers.uefaTriggers.focalPoint.split(":")[1] / this.ratio);
    }
  },
  watch: {
    selectedContent( newVal,oldVal) {
      this.selectedContentForTriggerEdit = newVal;
      this.calculateRatios(newVal);
      this.cssPlacementCoords.x = (this.selectedContentForTriggerEdit.triggers.uefaTriggers.focalPoint.split(":")[0] / this.ratio);
      this.cssPlacementCoords.y = (this.selectedContentForTriggerEdit.triggers.uefaTriggers.focalPoint.split(":")[1] / this.ratio);        
    },

    defaultVal(val) {
      this.imagePlacementCoords.x = val.split(":")[0];
      this.imagePlacementCoords.y = val.split(":")[1];
    },
  },
  methods: {
    calculateRatios(newVal) {
		
	this.xDisabled = false;
	this.yDisabled = false;
	
	let w = newVal.media.width;
	let h = newVal.media.height;
	
      let visibleAreaWidth = newVal.triggers.tweet !== undefined ? 480 : 360;
      let visibleAreaHeight = newVal.triggers.tweet !== undefined ? 280 : 360;
	  
	  this.visibleWidth = visibleAreaWidth;
	  this.visibleHeight = visibleAreaHeight;
	  
      let ratioW = w / visibleAreaWidth;
      let ratioH = h / visibleAreaHeight;

      let inverseRatioW = visibleAreaWidth / w;
      let inverseRatioH = visibleAreaHeight / h;
	  
      let visibleAreaWidthUpdated = visibleAreaWidth * inverseRatioH;
      let visibleAreaHeightUpdated = visibleAreaHeight * inverseRatioH;
	  if(h >= w)
	  {
		  this.xDisabled = true;
		  this.contentNewHeight = h * inverseRatioW;
		  this.contentNewWidth = w * inverseRatioW;
		  this.ratio = ratioW;
	    this.$emit("ratioChange", this.ratio);
	  }
      else if (w > h) {
        this.contentNewWidth = w * inverseRatioH;
        this.contentNewHeight = h * inverseRatioH;
        this.ratio = ratioH;
        this.$emit("ratioChange", this.ratio);
		    this.yDisabled = true;
      }
	  
	  if(this.contentNewHeight < visibleAreaHeight)
	  {
		  this.xDisabled = false;
		  this.yDisabled = true;
		  this.contentNewHeight = h * inverseRatioH;
		  this.contentNewWidth = w * inverseRatioH;
		  this.ratio = ratioW;
		  this.$emit("ratioChange", this.ratio);
	  }
	  else if(this.contentNewWidth < visibleAreaWidth)
	  {
		  this.yDisabled = false;
		  this.xDisabled = true;
		  this.contentNewHeight = h * inverseRatioW;
		  this.contentNewWidth = w * inverseRatioW;
		  this.ratio = ratioW;
		  this.$emit("ratioChange", this.ratio);
	  }
    },
    updateCoordsParent() {
      this.$emit("coordChange", this.cssPlacementCoords);
    },
	moveCoordsY(amount)
	{
		let newPlacement = this.cssPlacementCoords.y + amount;
		if(newPlacement > 0)
		{
			newPlacement = 0;
		}
		else
		{
			if(newPlacement < -(this.contentNewHeight - this.visibleHeight))
				newPlacement = -(this.contentNewHeight - this.visibleHeight);
		}
		this.cssPlacementCoords.y = newPlacement;
	},
	moveCoordsX(amount)
	{
		let newPlacement = this.cssPlacementCoords.x + amount;
		if(newPlacement > 0)
		{
			newPlacement = 0;
		}
		else
		{
			if(newPlacement < -(this.contentNewWidth - this.visibleWidth))
				newPlacement = -(this.contentNewWidth - this.visibleWidth);
		}
		this.cssPlacementCoords.x = newPlacement;
	}
  },
  data() {
    return {
      selectedContentForTriggerEdit: this.selectedContent,
      imagePlacementCoords: { x: 0, y: 0 },
      contentNewWidth: 0,
      contentNewHeight: 0,
      cssPlacementCoords:{ x: 0, y: 0 },
      ratio: 0,
      xDisabled: false,
      yDisabled: false,
      visibleWidth: 0,
      visibleHeight: 0,
    };
  },
};
</script>