import Fuse from "fuse.js";

const keyify = (obj, prefix = "") => {
  const uniqueKeys = new Set();

  const traverse = (obj, prefix) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => traverse(item, ""));
    } else if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        const newPrefix = prefix ? `${prefix}.${key}` : key;
        uniqueKeys.add(newPrefix);
        traverse(obj[key], newPrefix);
      });
    }
  };

  traverse(obj, prefix);
  return Array.from(uniqueKeys);
};

export default (collection, params, search, threshold = 0.25, fallbackParams = false) => {
  // If no collection is provided, return an empty array
  if (!collection) {
    return [];
  }

  // If no search is provided, return a shallow copy of the collection
  if (!search || search.trim() === "") {
    return collection.slice();
  }

  // If we want to search all the keys, we need to map them to an array
  if (params === "all") {
    params = keyify(collection);
  }

  // Turn off fuzzy Search based on input
  switch (true) {
    case /^K\d+/.test(search):
    case /^PANFR\d+/.test(search): // New case for PANFR followed by numbers
      threshold = 0;
      break;
    case /^k\d+/.test(search):
      search = search.replace(/^k/, 'K'); // Convert lowercase k to uppercase K
      threshold = 0;
      break;
    case /^panfr\d+/.test(search): // New case for panfr followed by numbers
      search = search.toUpperCase(); // Convert to uppercase
      threshold = 0;
      break;
    case /^\d+$/.test(search):
      threshold = 0;
      break;
  }
  
  
  // Fuse.js options
  const options = {
    keys: params,
    threshold: threshold,
    ignoreLocation: true,
  };
  // Search
  let finalRes = [];

  // Fuse.js instance
  let fuse = new Fuse(collection, options);

  fuse.search(search).forEach((item) => {
    finalRes.push(item.item);
  });

  if (fallbackParams && finalRes.length === 0) {
    options.keys = keyify(collection);
    fuse = new Fuse(collection, options);
    fuse.search(search).forEach((item) => {
      finalRes.push(item.item);
    });
  }
  return finalRes;
};
