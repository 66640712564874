<template>
    <div></div>
</template>
<script>
export default {
    name: 'IndexLocations',
    data() {
        return {
            data: null,
        }
    }
}
</script>