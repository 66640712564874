<template>
  <v-container ref="rootContainer">
    <!-- Controls -->
    <v-row 
    style = "margin-top: 2px;"

      ref="controlsContainer"
    >
      <!-- Location -->
      <v-col 
        cols="6"
        class="ma-0 pa-0 pr-0"
      >
        <v-autocomplete
          v-model="previewLocationHolder"
          :onchange="selectLocation()"
          label="Location"
          outlined
          return-object
          :items="locationsList"
          :item-text="(item) => (item.idA?item.idA+'':item.idE+'') + ' - ' + item.name + (item.region ? (' - ' + item.region) : '')"
        />
      </v-col>
      <!-- Date -->
      <v-col
        cols="3"
        class="ma-0 pa-0 pl-4"
      >

      <v-menu
            v-model="clockDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field 
                type="text"
                v-model="previewDate" 
                v-bind="attrs"
                v-on="on"
                hint="YYYY-MM-DD"
                label="Clock Date"
                outlined
              >
                <template slot="append">
                  <v-icon @click="fixDate">mdi-clock</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :show-current="true"
              v-model="previewDate" 
              no-title
              @input="clockDateMenu = false"
            ></v-date-picker>
          </v-menu>
      </v-col>
      <!-- Time -->
      <v-col
        cols="3"
        class="ma-0 pa-0 pl-4"
      >
        <v-text-field
          v-model="previewTime"
          label="Clock Time"
          placeholder="HH:MM:SS"
          hint="HH:MM:SS"
          outlined
        >
          <template slot="append">
            <v-icon @click="fixTime">mdi-clock</v-icon>
          </template>
        </v-text-field>
      </v-col>
        <!-- Preview buttons holder -->
        <v-col
            :cols="9"
            class="ma-0 pa-0 d-flex justify-left px-2"
          >
          <v-row
            class="ma-0 pa-0 pr-0 col-12">
            <!-- Draft -->
            <v-col
              v-if="screenLevelTriggers.includes('golive')"
              :cols="4"
              class="ma-0 pa-0 d-flex justify-center px-2"
            >
              <v-btn
                block
                @click="getPreviewPackage('draft')"
                color="primary"
                max-width="250px"
                style="height: 56px; font-size: 15px;"
                :disabled="generatingPreview"
                :loading="generatingPreview"
              >
                Draft
              </v-btn>
            </v-col>
            <!-- Preview -->
            <v-col 
              :cols="(screenLevelTriggers.includes('golive')) ? 4 : 12" 
              class="ma-0 pa-0 d-flex justify-center px-2"
            >
              <v-btn
                block
                @click="getPreviewPackage('preview')"
                color="primary"
                max-width="250px"
                style="height: 56px; font-size: 15px;"
                :disabled="generatingPreview"
                :loading="generatingPreview"
              >
                Preview
              </v-btn>
            </v-col>
            <!-- Live -->
            <v-col
              v-if="screenLevelTriggers.includes('golive')"
              :cols="4"
              class="ma-0 pa-0 d-flex justify-center px-2"
            >
              <v-btn
                block
                max-width="250px"
                style="height: 56px; font-size: 15px;"
                color="primary"
                @click="getPreviewPackage('live')"
                :disabled="generatingPreview"
                :loading="generatingPreview"
              >
                Scheduled
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!-- Small buttons holder -->
        <v-col
          :cols="3"
          class="ma-0 pa-0 d-flex justify-left px-2"
        >
          <!-- Campaign Recall -->
          <v-col
            :cols="3"
            class="ma-0 pa-0 d-flex justify-center px-2"
          >
          <v-tooltip content-class="top" top>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                block
                max-width="40px"
                style="height: 56px; font-size: 15px;"
                color="primary"
                @click="getCampaignRecall()"
                :disabled="generatingPreview"
                :loading="generatingPreview"
              >
                <v-icon large>mdi-refresh</v-icon>
              </v-btn>
            </template>
              <span>Campaign Recall</span>
          </v-tooltip>
          </v-col>
          <!-- Screengrab -->
          <v-col 
            :cols="3" 
            class="ma-0 pa-0 d-flex justify-center px-2"
            v-if="hasScreengrabImagePermission"
          >
          <v-tooltip content-class="top" top>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                block
                @click="getScreengrab()"
                color="primary"
                max-width="40px"
                style="height: 56px; font-size: 15px;"
                :disabled="previewUrl == null || generatingScreengrab"
                :loading="generatingScreengrab"
              >
                <v-icon large>mdi-camera</v-icon>
            </v-btn>
            </template>
              <span>Image Screengrab</span>
          </v-tooltip>
          </v-col>
          <!-- Video Screengrab -->
          <v-col 
            :cols="3" 
            class="ma-0 pa-0 d-flex justify-center px-2"
            v-if="hasScreengrabVideoPermission"
          >
          <v-tooltip content-class="top" top>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                block
                @click="getScreengrab(true)"
                color="primary"
                max-width="40px"
                style="height: 56px; font-size: 15px;"
                :disabled="previewUrl == null || generatingScreengrabVideo"
                :loading="generatingScreengrabVideo"
              >
                <v-icon large>mdi-video</v-icon>
            </v-btn>
            </template>
              <span>Video Screengrab</span>
          </v-tooltip>
          </v-col>
          <!-- Package Screengrabs -->
          <v-col 
            :cols="3" 
            class="ma-0 pa-0 d-flex justify-center px-2"
            v-if="hasScreengrabCollectionPermission"
          >
          <v-tooltip content-class="top" top>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                block
                @click="getScreengrabPackage()"
                color="primary"
                max-width="40px"
                style="height: 56px; font-size: 15px;"
                :disabled="previewUrl == null || generatingScreengrabPackage"
                :loading="generatingScreengrabPackage"
              >
                  <v-icon large>mdi-package</v-icon>
              </v-btn>
              </template>
              <span>Entire package image screengrab</span>
          </v-tooltip>
          </v-col>
        </v-col>
      
      <!-- Dimensions -->
      <v-row 
        v-if="this.$store.state.Permissions.activeLocationView"
        class="ml-0 mr-0 pa-4" 
        cols=12
      >
          <v-col cols=5></v-col>
          <v-col cols=1 class="ma-0 pa-0">
            <v-text-field height="20px" v-model="previewWidth" type="number" class="ma-0 pa-0 centered-input" />
          </v-col> 
          x 
          <v-col cols=1 class="ma-0 pa-0">
            <v-text-field height="20px" v-model="previewHeight" type="number" class="ma-0 pa-0 centered-input" />
          </v-col>
          <v-icon @click="grabSelectedScreenWH">mdi-arrow-u-left-top</v-icon>
          <v-col cols=5></v-col>
      </v-row>
      
    </v-row>

     <!-- Main -->
     <div class="d-flex flex-row flex-nowrap">
    <!-- Left -->
    <!-- Scale -->
    <v-container>
    <v-row 
      v-if="previewUrl && renderIframe"
      ref="scaleContainer"
      class="mt-5"
      style="
        width: 100%;
      "
    >
        <div class="ml-2 mr-5" style="width: 35px;">
          <v-text-field v-model="scaleMin" class="ma-0 pa-0 centered-input" />
        </div>
        <v-slider
          v-model="previewScale"
          :min="scaleMin"
          :max="scaleMax"
          thumb-color="primary"
          thumb-label="always"
          @change="allowXOverflow"
        ></v-slider>
        <div 
          class="mr-2 ml-5" 
          :style="{ width: '35px' }"
        >
          <v-text-field v-model="scaleMax" class="ma-0 pa-0 centered-input" />
        </div>
      </v-row>

      <!-- IFrame -->
      <v-row v-if="previewUrl && renderIframe" class="ma-2 pa-2 mb-4">
        <a 
          :href="previewUrl" style="text-decoration: none" 
          target="_blank"
        >
          <v-btn class="">Click to open in a new tab</v-btn>
        </a>
        <v-tooltip top v-if="hasPreviewShowCssPermission">
          <template v-slot:activator="{ on }">
            <v-hover v-slot="{ hover }">
              <v-btn
                large
                v-on="on"
                fab
                :color="hover || showCSS ? 'primary' : 'gray'"
                @click="showCSS = !showCSS"
                style="margin-left: 10px; margin-top: -10px;"
              >
                <v-icon large>mdi-language-css3</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>Show CSS</span>
        </v-tooltip>
        <v-tooltip top v-if="hasPreviewShowXmlPermission">
          <template v-slot:activator="{ on }">
            <v-hover v-slot="{ hover }">
              <v-btn
              
                large
                v-on="on"
                fab
                :color="hover || showDebug ? 'primary' : 'gray'"
                @click="showDebug = !showDebug"
                style="margin-left: 10px; margin-top: -10px;"
              >
                <v-icon large>mdi-file-xml-box</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>Show Debug XML</span>
        </v-tooltip>
      </v-row>
      <div
        ref="iFrameScroll"
        v-if="previewUrl && renderIframe"
        class="pb-5"
        :style="{
          width: '100%',
          height: 'fit-content',
          overflowX: (previewPan) ? 'auto' : 'hidden',
        }"
      >
        <div
          ref="iFrameContainer"
          :style="{
            width: previewWidth + 'px',
            height: previewHeight + 'px',
            transform: 'scale('+ previewScale/100 +')',
            transformOrigin: '0 0',
          }"
        >
          <iframe
            ref="iFrameView"
            :style="{
              width: '100%',
              height: '100%',
              border: 'none',
            }"
            :src="previewUrl"
            allow="autoplay"
            id="previewIframe"
            onLoad="this.name = Date.now();"
          >

          </iframe>
        </div>
        <div v-if="showDebug" style="/*visibility:hidden;*/">
          <v-btn class="ma2 pa2" @click="test();">Show XML</v-btn>
          <v-btn class="ma2 pa2"  @click="loadModifiedXML();" >Load Modified XML</v-btn>
          <v-btn class="ma2 pa2" @click="callBroadSignPlay();">Call BroadSignPlay</v-btn>
          <v-textarea
            v-model="xmlPreviewString"
            id="previewXML"
            full-width
          ></v-textarea>
        </div>
      </div>
    </v-container>
    <!-- Right -->
    
    <v-card
      v-if="showCSS"
      class="ml-3 rounded d-flex flex-column"
      style="width: 25%"
      
      >
      <v-card-title style="width: 100%" class="d-flex justify-center pa-4">
        CSS STYLES
      </v-card-title>
      <!-- Body -->
      <v-card-text class="d-flex flex-column px-2 border-2">
        <div v-if="checkResourceIncludeStyle">
          <v-col
            cols="12"
            class="py-0 my-0 mt-4"
          >
            <v-row class="d-flex justify-center align-center mb-3" style="gap: 1.5em;">            
              <v-btn
                v-if="checkResourceIncludeStyle"
                @click="prettifyCSS = !prettifyCSS"
                fab
                small
                color="primary"
              ><v-icon small>{{ (prettifyCSS) ? 'mdi-language-css3' : 'mdi-file-xml-box' }}</v-icon></v-btn>

              <v-btn
                color="primary"
                @click="setText(resourceAvailable[0], `textarea_resource${resourceAvailable[0].resourceId}`)"
              >
                Save
              </v-btn>
              
            </v-row>
            <v-textarea
              :ref="'textarea_resource' + resourceAvailable[0].resourceId"
              style="overflow-y: auto; height: calc(60vh - 100px); background-color: black; padding: 0px; padding: 10px;"
              :value="(prettifyCSS) ? cssFormatter : resourceAvailable[0].text"
              auto-grow
              @change="previewText(resourceAvailable[0], `textarea_resource${resourceAvailable[0].resourceId}`)"
            />
          </v-col>
        </div>
      </v-card-text>
    </v-card>
  </div>
  </v-container>
</template>

<script>
  import CampaignController from '@/services/controllers/Campaign'
  import moment from 'moment'
  export default {
    props: ['selectedBurst', 'selectedScreen', 'selectedCampaignBurstScreen', 'screenLevelTriggers', 'locationsList', 'previewLocation'],

    data: () => ({
      xmlPreviewString: "test not xml",
      cssPreviewString: 'css styles',
      scaleMin: 10,
      scaleMax: 100,
      previewRatio: 0,
      previewWidth: 0,
      previewHeight: 0,
      previewPan: false,
      previewScale: 20,
      campaignBurstScreens: null,
      campaignBursts: null,
      previewTime: '00:00:00',
      previewDate: '2022-01-01',
      previewUrl: null,
      screengrabFilename: null,
      renderIframe: false,
      clockDateMenu: false,
      previewLocationHolder: '',
      generatingScreengrab: false,
      generatingScreengrabVideo: false,
      generatingScreengrabPackage: false,
      generatingPreview: false,
      // CSS
     
      screenResourcesAvailable: null,
      prettifyCSS: true,
      resourceAvailable: null,
      mode: 'settings',
      showCSS: false,
      showDebug: false,
      lastPublishState: null,
    }),

    mounted() {
      this.getCurrentOrDefaultLocation()
      this.fixDate()
      this.fixTime()
      this.grabSelectedScreenWH()
    },
    created() {
      CampaignController.getScreenResources(this.selectedCampaignBurstScreen.id)
        .then((res) => {
          this.resourceAvailable = res.data.filter((resource) => this.shouldShowResource(resource))
          this.$nextTick()
        })
        .catch((err) => {
        })
    },
    computed: {
      checkResourceIncludeStyle(){
        if(this.resourceAvailable === null) return false
        if(this.resourceAvailable[0]){
          return this.resourceAvailable[0].resourceType.shortName.includes('style')
        } else {
          return false
        }
      },
      hasPreviewShowCssPermission() {
        return this.$store.state.Permissions.previewShowCss
      },
      hasPreviewShowXmlPermission() {
        return this.$store.state.Permissions.previewShowXml
      },
      hasScreengrabImagePermission() {
        return this.$store.state.Permissions.screengrabImage
      },
      hasScreengrabCollectionPermission() {
        return this.$store.state.Permissions.screengrabCollection
      },
      hasScreengrabVideoPermission() {
        return this.$store.state.Permissions.screengrabVideo
      },

      cssFormatter() {
      let result = ''
      let { text } = this.resourceAvailable[0]
      
      if(!text) // text might be null when the 'css styles' resource is newly added to a screen
        return result 

      text = text.replace(/text_scaling='false'|text_scaling='true'|text_scaling="false"|text_scaling="true"| text_scaling='false'| text_scaling='true'| text_scaling="false"| text_scaling="true"/g, '')

      // First split by css id used
      let ids = text.split(/<style id='|<style id="/)

      for (let index = 1; index < ids.length; index++) {
        let id = ids[index];

        id = id.replace(/^/, '#')
        id = id.replace(/'>|">| '>| ">/, ' {')
        id = id.replace('</style>', '}')

        id = id.replaceAll('<![CDATA[', ' {\n\t\t')
        id = id.replaceAll(']]>', '\n\t}')

        id = id.replace(/<\/.*>/g, '')
        id = id.replaceAll('<', '')
        id = id.replaceAll('>', '')
        id = id.replace(/\;(?=.*\;)/g, ';\n\t\t')

        result += id
      }
      return `${result}`
    }
    },
    watch: {
      locationsList: function () {
        this.getCurrentOrDefaultLocation()
      },

      selectedCampaignBurstScreen: function () {
        this.fixDate()
        this.fixTime()
      },
      
      selectedScreen: function () {
        this.previewUrl = null,
        this.screengrabFilename = null,
        this.grabSelectedScreenWH();
      }
      
    },

    methods: {
      async previewText (resource, resourceRef) {
        // refetrence textholder in document
        // textHolder 
        //window.frames[0].document.getElementById('textHolder')
        // 

        if (resource.resourceType.type === 'textarea' || resource.resourceType.type === 'text') {
          // Append new styles to DOM for preview
          if(window.frames[0].document.getElementById('text1') !== undefined ){
            const cssText = window.frames[0].document.getElementById('text1').parentElement.style.cssText;
            // Define a regular expression to match the last CSS property that ends with "--style"
            const stylePropRegex = /(--[\w-]+)\s*:\s*([^;]+)\s*(?:;|$)/g;
            let matched;
            let lastStyleProp;
            while ((matched = stylePropRegex.exec(cssText)) !== null) {
              lastStyleProp = matched;
            }
            // Last css prop validation
            if (lastStyleProp) {
              var [prop, value] = lastStyleProp.slice(1);
              //console.log(prop, value);
              // You can now use the "prop" and "value" variables as needed
            } else {
              console.log('No CSS property ending with "--style" was found');
            }
            // Create a regular expression with the variable using template literals syntax
            const regex = new RegExp(`#${value}\\s*{([^}]*text1\\s*{[^}]*})`);

            const match = this.$refs[resourceRef].internalValue.match(regex);
            if (match) {
              const text1Data = match[1].trim(); // Extract the data within the curly braces and remove leading/trailing white space
              // Extract the contents of text1 and format it as a valid CSS style string
              const contentsRegex = /{([\s\S]*)}/g; // Match everything between the curly braces
              const contentsMatch = contentsRegex.exec(text1Data);
              const contents = contentsMatch ? contentsMatch[1].trim() : '';
              // Assign the new styles
              window.frames[0].document.getElementById('text1').parentElement.style.cssText = contents 
            }
          }
        }
        
      },
      async setText (resource, resourceRef) {
        if (resource.resourceType.type === 'textarea' || resource.resourceType.type === 'text') {
          let resourceObj = {}
          
          if(resource.resourceType.name.toLowerCase() === 'css style') {
            this.$refs[resourceRef].internalValue = this.formatCssToXml(this.$refs[resourceRef].internalValue)
          }

          if (resource.resourceType.level === 'screen') {
            resourceObj = {
              campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
              resourceShortName: resource.resourceType.shortName,
              text: this.$refs[resourceRef].internalValue,
            }
          }

          // Update the reactive data property bound to the v-textarea
            this.resourceAvailable[0].text = this.$refs[resourceRef].internalValue;

          const { data = null } = await CampaignController.setResource(resourceObj)
          // Catch error
          .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

          if(data) {
            this.$root.$emit('snackbarSuccess', 'Saved successfully.')
            this.getPreviewPackage('preview')
          }
        }
      },
      formatCssToXml(css) {
        if(this.prettifyCSS) {
          let newCss = ''

          // Replace # in css props with temp var before splitting by # on next line
          newCss = css.replace(/: #|:#/g, "hexCodeTempVar")
          
          // Convert the id to an xml style tag
          newCss = newCss.replaceAll('#', "<style id='")

          // Replace temp css hex code with a #
          newCss = newCss.replaceAll('hexCodeTempVar', ": #")
          
          // Replace closing brackets with closing chevrons
          newCss = newCss.replace(/{| {|  {|   {|    {|     {/g, '>')
          newCss = newCss.replace(/\s>/g, ">")
          
          // Add opening tags for the img[] & text[] css selectors 
          newCss = newCss.replace(/(text([0-9]*)>)/g, '<$1')
          newCss = newCss.replace(/(img([0-9]*)>)/g, '<$1')
          
          // Split by ids to loop through and structure the css into xml
          let ids = newCss.split("<style id='")

          // Reset new css var to append the result to
          newCss = ''
          
          // Loop through ids (ignore index 0 because its invalid)
          for (let i = 1; i < ids.length; i++) {
            let result = ''
            let id = ids[i];

            // Add the style back because it gets stripped in the above split
            id = id.replace(/^/, "<style id='")
            id = id.replace('>', "'>")

            // Close off style tag
            id = id.substring(0, id.lastIndexOf("}")) + '</style>';

            // Split into id opening tag, props & id closing tag
            let blocks = id.split('<')
            // loop through blocks (ignore index 0 because its invalid)
            for (let j = 1; j < blocks.length; j++) {
              let block = blocks[j];

              // Fix id opening tag
              if (j === 1 || j === blocks.length - 1) {
                block = '<' + block
              }

              else {
                let body = block.split('>')
                
                body[1] = body[1].replace('}', `]]></${body[0]}>`)
                
                body[0] = `<${body[0]}><![CDATA[`

                let header = body[0].trim()
                let footer = body[0].replace('<', '</')
                footer = footer.replace('<![CDATA[', '')
                  
                block = body[0] + body[1]

                let props = block.split('[')

                props = props[2].split(']')

                props = props[0].split(/;/g)

                let newProp = ''

                for (let k = 0; k < props.length - 1; k++) {
                  const prop = props[k].trim()
                  newProp += prop + ';'
                }

                header = (j === 2) ? header : '\t' + header

                block = header + newProp + ']]>' + footer + '\n'
              }              

              result += block
            }

            newCss += (i === 1) ? result : '\n' + result
          }

          this.prettifyCSS = false
          
          return newCss
        }

        return css
      },
      shouldShowResource(resource)
      {
        return (!resource.resourceId || resource.visible) && ((resource.resourceType.type == 'media' && this.mode == "resources") || (resource.resourceType.type != 'media' && this.mode == "settings"));
      },
      callBroadSignPlay() {
        window.frames[0].BroadSignPlay();
      },
      resetPreview(element) {
        element.document.getElementById("fallbackHolder").style.visibility = "hidden";
        element.document.getElementById("DOT").childNodes.forEach((x) => { x.remove()});
        element.document.getElementById("bg").childNodes.forEach((x) => { x.remove()});
        element.document.getElementById("textHolder").childNodes.forEach((x) => { x.remove()});
        element.document.getElementById("_frameIDCode").childNodes.forEach((x) => { x.remove()});
        element.document.getElementById("imageHolder").childNodes.forEach((x) => { x.remove()});
        element.document.getElementById("imageHolder").style.visibility = "visible";
        element.errorBlocked = false;
      },
      showXML() {
        this.xmlPreviewString = ""+window.frames[0].xmlInJSVar.replaceAll("><",">  \n  <")+"";
      },
      test() {
//        document.getElementById("previewXML").innerHTML = "Error couldn't find xml";
        this.showXML();
        // console.log("xmlInJar",window.frames[0].xmlInJSVar);
        //console.log("xmlInJar",document.getElementById('targetFrame').contentWindow.xmlInJSVar);
        //console.log("previewURL",this.previewUrl);
      },
      loadModifiedXML() {
//        console.log(""+this.xmlPreviewString
//            .replace("<xmp>","").replace("</xmp>","")
//            .replaceAll("&lt;","<").replaceAll("&gt;",">")
//            .replaceAll(">  \n  <","><"));
        let element = window.frames[0];
        this.resetPreview(element);
/*        element.xmlInJSVar = document.getElementById("previewXML").innerHTML
            .replace("<xmp>","").replace("</xmp>","")
            .replaceAll("&lt;","<").replaceAll("&gt;",">")
            .replaceAll("\n","");
*/
        element.xmlInJSVar = this.xmlPreviewString
//            .replace("<xmp>","").replace("</xmp>","")
//            .replaceAll("&lt;","<").replaceAll("&gt;",">")
            .replaceAll(">  \n  <","><");
        element.init();
      },
      grabSelectedScreenWH() {
        this.previewWidth = this.selectedScreen.width
        this.previewHeight = this.selectedScreen.height
      },
      selectLocation() {
        this.$emit('setPreviewLocation', { newLocation: this.previewLocationHolder })
      },
      getCurrentOrDefaultLocation() {
        if(this.locationsList.length > 0)
        {
          const searchLocations = (what) => this.locationsList.find((element) => element.idA === what)
          let changeTo = searchLocations(this.previewLocation.idA)
          if(changeTo)
            this.previewLocationHolder = changeTo
          else
            this.previewLocationHolder = this.locationsList[0]
        }
      },
      allowXOverflow() {
        // Width of container
        const rw = this.$refs.rootContainer.clientWidth - 10
        // Width of image
        const fw = this.$refs.iFrameContainer.clientWidth * (this.previewScale/100)

        // Allow x overflow to be on or off
        this.previewPan = (fw > rw) ? true : false
      },

      previewAtFullScale() {
        // Browser height
        const pxHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

        // To work out y offset of preview image (in px's where top of the image plots)
        let yOffset = this.$refs.scaleContainer.clientHeight + 30

        // Scale for full screen width
        const fullWidth = Math.floor(this.$refs.rootContainer.clientWidth / this.previewWidth * 100) - 1
        
        // Scale for full screen height
        const fullHeight = Math.floor((pxHeight - yOffset) / this.previewHeight * 100) - 1

        // Override if not enough height for full width view
        const override = (this.previewHeight * (fullWidth/100) + yOffset < pxHeight) ? true : false

        // Which orientation to scale for
        const orientation = (override && this.previewWidth > this.previewHeight) ? 'w' : 'h'

        // Set the preview scale
        this.previewScale = (orientation === 'w') ? fullWidth : fullHeight

        document.body.scrollTop = this.$refs.controlsContainer.getBoundingClientRect().top + this.$refs.controlsContainer.clientHeight - 25;
        document.documentElement.scrollTop = this.$refs.controlsContainer.getBoundingClientRect().top + this.$refs.controlsContainer.clientHeight - 25;
      },

      fixDate () {
        this.previewDate = moment().format('YYYY-MM-DD')
      },

      fixTime () {
        this.previewTime = moment().format('HH:mm:ss')
      },

      getPreviewPackage (publishState) {
        this.renderIframe = false
        this.generatingPreview = true
        this.lastPublishState = publishState
        CampaignController.getPreview({
          campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          frameId: this.previewLocation.idA?this.previewLocation.idA:this.previewLocation.idE,
          date: this.previewDate,
          time: this.previewTime,
          live: publishState,
          width: this.previewWidth,
          height: this.previewHeight
        })
        .then((res) => {
          this.renderIframe = true
          this.previewUrl = res.data.url
          this.screengrabFilename = res.data.screengrabFilename
        })
        .then(() => {
          this.previewAtFullScale()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })
        .finally(() => {
          this.generatingPreview = false
        });
      },

      getCampaignRecall () {
        this.renderIframe = false
        this.generatingPreview = true
        var publishState = 'live';
        this.lastPublishState = publishState
        CampaignController.getCampaignRecall({
          campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          frameId: this.previewLocation.idA?this.previewLocation.idA:this.previewLocation.idE,
          date: this.previewDate,
          time: this.previewTime,
          live: publishState,
          width: this.previewWidth,
          height: this.previewHeight
        })
        .then((res) => {
          this.renderIframe = true
          this.previewUrl = res.data.url
          this.screengrabFilename = res.data.screengrabFilename
        })
        .then(() => {
          this.previewAtFullScale()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })
        .finally(() => {
          this.generatingPreview = false
        });
      },

      getScreengrab (isVideo = false) {
        this.generatingScreengrab = (!isVideo) ? true : false;
        this.generatingScreengrabVideo = (isVideo) ? true : false;

        // renames file (of unknown extension and possibly having . in filename) to .mp4 if video
        // get last instance of . in filename
        const lastDotIndex = this.screengrabFilename.lastIndexOf('.')
        // get the extension
        const extension = this.screengrabFilename.substring(lastDotIndex)
        // replace extension with .mp4 if video
        this.screengrabFilename = isVideo ? this.screengrabFilename.replace(extension, '.mp4') : this.screengrabFilename

        CampaignController.getScreengrab({
          campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          url: this.previewUrl,
          filename: this.screengrabFilename,
          width: this.previewWidth,
          height: this.previewHeight
        })
        .then((res) => {
          const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
          const fileName = folderPath[folderPath.length-1]
          const blob = new Blob([res.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
            err.response.data.text().then((errRes) => {
              this.$root.$emit('snackbarError', JSON.parse(errRes).message) // because responseType is set to blob
            })
          })
        .finally(() => {
          this.generatingScreengrab = (!isVideo) ? false : this.generatingScreengrab;
          this.generatingScreengrabVideo = (isVideo) ? false : this.generatingScreengrab;
        });
      },
      getScreengrabPackage () {
        this.generatingScreengrabPackage = true
        CampaignController.getScreengrabPackage({
          campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          frameId: "", // will be overwritten by the backend
          date: this.previewDate,
          time: this.previewTime,
          live: this.lastPublishState,
          width: this.previewWidth,
          height: this.previewHeight
        })
        .then((res) => {
          // download the zip package
          const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
          const fileName = folderPath[folderPath.length-1]
          const blob = new Blob([res.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', err.response.data.message)
        })
        .finally(() => {
          this.generatingScreengrabPackage = false
        });
      },
    },
  }

</script>
