<template>
    <v-container>
        <v-card
            v-if="selectedScreen"
            class="mx-auto mt-8"
            max-width="650"
        >
            <!-- Screen title -->
            <v-card-title class="d-flex justify-space-between primary pb-3">
                <div>
                    <h3>{{ isValid(selectedScreen.name) }}</h3>
                    <h5>{{ isValid(selectedScreen.mediaOwner.friendlyName) }}</h5>
                </div>
                <div v-if='this.$store.state.Permissions.screenManagement' class="mt-n10 mr-n3">
                    <v-icon @click="goToEditScreen()" class="mouse-pointer" style="font-size: 26px">mdi-pencil-circle-outline</v-icon>
                </div>
            </v-card-title>

            <!-- Screen info -->
            <v-card-text class="d-flex justify-space-between">
                <div class="pt-5" style="width: 50%;">
                    <div class="pb-3">
                        <div class="text-subtitle-1">Category:</div>
                        <div @click="copyLink(isValid(selectedScreen.category))" class="caption mouse-pointer">{{ isValid(selectedScreen.category) }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Rotation:</div>
                        <div @click="copyLink(isValid(selectedScreen.rotation))" class="caption mouse-pointer">{{ isValid(selectedScreen.rotation) }}{{ (selectedScreen.rotation) ? '°' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Width:</div>
                        <div @click="copyLink(isValid(selectedScreen.width))" class="caption mouse-pointer">{{ isValid(selectedScreen.width) }} {{ (selectedScreen.width) ? 'pixels' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Aspect ratio:</div>
                        <div @click="copyLink(isValid(selectedScreen.aspectRatio))" class="caption mouse-pointer">{{ isValid(selectedScreen.aspectRatio) }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">OffsetX:</div>
                        <div @click="copyLink(isValid(selectedScreen.offsetX))" class="caption mouse-pointer">{{ isValid(selectedScreen.offsetX) }} {{ (selectedScreen.offsetX) ? 'pixels' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Slot Duration:</div>
                        <div @click="copyLink(isValid(selectedScreen.slotDurationSeconds))" class="caption mouse-pointer">{{ isValid(selectedScreen.slotDurationSeconds) }} {{ (selectedScreen.slotDurationSeconds) ? 'seconds' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">File name convention:</div>
                        <div @click="copyLink(isValid(selectedScreen.filenameConventionPrefix))" class="caption mouse-pointer">{{ isValid(selectedScreen.filenameConventionPrefix) }}</div>
                    </div>

                    <!-- dynamicScreen -->
                    <template v-if="selectedScreen.dynamicScreen">
                        <div class="pb-3" v-if="selectedScreen.dynamicScreen.operatingSystem">
                            <div class="text-subtitle-1">Operating System:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.operatingSystem.name))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.operatingSystem.name) }}</div>
                        </div>
                        <div class="pb-3" v-if="selectedScreen.dynamicScreen.publishType">
                            <div class="text-subtitle-1">Publish Type:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.publishType.name))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.publishType.name) }}</div>
                        </div>
                        <div class="pb-3">
                            <div class="text-subtitle-1">Responsive:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.isResponsive))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.isResponsive) }}</div>
                        </div>
                    </template>                   
                </div>

                <div class="pt-5" style="width: 50%;">
                    <div class="pb-3">
                        <div class="text-subtitle-1">Link to format:</div>
                        <div v-if="selectedScreen.webLink" class="caption"> 
                            <a target="_blank" :href="selectedScreen.webLink" >
                                <div class="text-truncate">{{selectedScreen.webLink}}</div>
                            </a>
                        </div>
                        <div v-else class="caption text-truncate">{{ isValid(selectedScreen.webLink) }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Orientation:</div>
                        <div @click="copyLink(isValid(selectedScreen.orientation))" class="caption mouse-pointer">{{ isValid(selectedScreen.orientation) }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Height:</div>
                        <div @click="copyLink(isValid(selectedScreen.height))" class="caption mouse-pointer">{{ isValid(selectedScreen.height) }} {{ (selectedScreen.height) ? 'pixels' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">OffsetY:</div>
                        <div @click="copyLink(isValid(selectedScreen.offsetY))" class="caption mouse-pointer">{{ isValid(selectedScreen.offsetY) }} {{ (selectedScreen.offsetY) ? 'pixels' : '' }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Motion Support:</div>
                        <div @click="copyLink(isValid(selectedScreen.motionSupport))" class="caption mouse-pointer">{{ isValid(selectedScreen.motionSupport) }}</div>
                    </div>

                    <div class="pb-3">
                        <div class="text-subtitle-1">Max File Size:</div>
                        <div @click="copyLink(isValid(selectedScreen.maxFileSizeKB))" class="caption mouse-pointer">{{ isValid(selectedScreen.maxFileSizeKB) }} {{ (selectedScreen.maxFileSizeKB) ? 'kilobytes' : ''}}</div>
                    </div>

                    
                    <!-- dynamicScreen -->
                    <template v-if="selectedScreen.dynamicScreen">
                        <div class="pb-3" v-if="selectedScreen.dynamicScreen.playerSoftware">
                            <div class="text-subtitle-1">Player Software:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.playerSoftware.name))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.playerSoftware.name) }}</div>
                        </div>
                        <div class="pb-3" v-if="selectedScreen.dynamicScreen.machineType">
                            <div class="text-subtitle-1">Machine Type:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.machineType.name))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.machineType.name) }}</div>
                        </div>
                        <div class="pb-3">
                            <div class="text-subtitle-1">Sync Frequency:</div>
                            <div @click="copyLink(isValid(selectedScreen.dynamicScreen.syncFrequencySeconds.name))" class="caption mouse-pointer">{{ isValid(selectedScreen.dynamicScreen.syncFrequencySeconds.name) }}</div>
                        </div>
                    </template>
                </div>
            </v-card-text>

            <!-- Field Notes -->
            <div  v-if="this.$store.state.Permissions.screenSpecsView && fieldNotes && fieldNotes.length > 0" class="mt-4 pt-1" style="position: relative;">
                <div 
                    @click="showFieldNotes = !showFieldNotes" 
                    class="d-flex justify-center text-center mouse-pointer"
                    style="width: 100%; max-height: 20px; background: #2c2c2c;"
                >
                    <div style="width: fit-content">
                        <v-icon style="color:white;">{{(showFieldNotes) ? 'mdi-triangle-small-up' :'mdi-triangle-small-down'}}</v-icon>
                    </div>
                </div>
                
                <div class="pt-8 pb-2" v-if="showFieldNotes" style="max-height: 400px; overflow-y: auto;">
                    <div v-for="(note, index) in returnFieldNotes" :key="index" class="mx-4 mb-6">
                        <div class="d-flex justify-space-between pb-1">
                            <span 
                                class="caption d-flex align-center" 
                                style="color: grey"
                            >
                                created {{note.dateCreated | moment}}
                                <v-icon small color="grey darken-" class="ml-1">mdi-clock-check</v-icon>
                            </span>
                            <span 
                                class="caption d-flex align-center"
                                style="color: grey"
                            >
                                <v-icon small color="grey" class="mr-1">mdi-clock-edit</v-icon>
                                updated {{note.dateUpdated | moment}}
                            </span>
                        </div>
                        <div>{{note.fieldNote}}</div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import collectionSort from '@/mixins/collection/sort'
import moment from "moment";
import ScreenController from '@/services/controllers/Screen'

export default {
    props: ['selectedScreen'],

    data: () => ({
        showFieldNotes: false,
        fieldNotes: null
    }),

    filters: {
        moment: function (date) {
            return moment(date).fromNow();
        },
    },

    watch: {
      selectedScreen: function (newVal) {
          if(newVal)
            this.getFieldNotes()
      },
    },

    computed: {
        returnFieldNotes() {
            return collectionSort(this.fieldNotes, 'dateUpdated',  'desc')
        }
    },

    mounted(){
        if(this.selectedScreen){
            this.getFieldNotes()
        }
    },

    methods: {
        isValid(prop) {
            return (prop || prop === 0) ? prop : 'N/A'
        },

        async getFieldNotes() {
            this.fieldNotes = await ScreenController.getFieldNotesPerScreen(this.selectedScreen.id)
            this.fieldNotes = this.fieldNotes.data
        },

        goToEditScreen() {
            let next = ''
            for (const [key, value] of Object.entries(this.$route.query)) {
                next += key + '^' + value + ','
            }
            next = next.substring(0, next.length - 1)

            this.$router.push({ 
                name: 'EditFormat', 
                query: { from: 'artwork', next, screenId: this.selectedScreen.id } 
            })
        },

        async copyLink(value) {
            await navigator.clipboard.writeText(value)
            this.$root.$emit('snackbarSuccess', '"' + value + '" copied to clipboard.')
        },
    }
}
</script>

<style>

</style>